
<template>
    <div :class="DLClass">
        <div class="img" v-if="!isOwner && DLClass == 'received-card'" style="margin-bottom: 15px;">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idTenant + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="img" v-if="isOwner && DLClass == 'received-card'">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idOwner + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="text">
            <div :class="'alert-request ' + locationStatusMeta.class" style="margin-bottom: 15px;">
            <h3><i :class="'fas ' + locationStatusMeta.icon"></i>
            {{locationStatusMeta.msg}}
            </h3>
            <div class="doc-box">
                <div class="text-box">
                    <h3>{{locationTitle}}</h3>
                    <h4>
                    Du {{locationDateStart}} au {{locationDateEnd}}
                    </h4>
                    <p>Pour {{locationNumberDays}} jours</p>
                    <h5>{{locationPrice}} €</h5>
                    <h6><a :href="linkDL" class="btn btn-primary"> Voir la demande </a></h6>
                </div>
            </div>
        </div>
        <div class="img" v-if="!isOwner && DLClass == 'send-card'" style="margin-bottom: 15px;">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idTenant + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        <div class="img" v-if="isOwner && DLClass == 'send-card'">
            <img v-if="image" :src="'http://www.lightyshare.com/images-upload/' + idOwner + '/profil/' + image" alt="">
            <div v-else :class="'avatar avatar-sm-48 avatar-color-3'">
                <div class="initials">{{initials}}</div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationCard',
    props: {
        DLCard : {
            type: Object
        },
    },
    computed: {
        locationStatusMeta () {
            const statusTexts = [
                {
                    class: 'alert-request1',
                    msg: 'Demande envoyée',
                    icon: 'fa-check'
                },
                {
                    class: 'alert-request5',
                    msg: 'Demande acceptée',
                    icon: 'fa-check'
                },
                {
                    class: 'alert-request5',
                    msg: 'Demande payée',
                    icon: 'fa-check'
                },
                {
                    class: 'alert-request2',
                    msg: 'Demande annulée',
                    icon: 'fa-times'
                },
            ]
            return statusTexts[this.locationStateKey]
        },
        locationStateKey () {
            let status = 0
            if (this.DLCard.state == "created") {
                status = 0
            } else if (this.DLCard.state == "accepted") {
                status = 1
            } else if (this.DLCard.state == "paid") {
                status = 2
            } else if (this.DLCard.state == "canceled") {
                status = 3
            }
            return status
        },
        locationTitle () {
            return this.DLCard.titreLocation
        },
        locationDateStart () {
            return this.$moment(this.DLCard.startAt).format('L')
        },
        locationDateEnd () {
            return this.$moment(this.DLCard.finishAt).format('L')
        },
        locationNumberDays () {
            return this.DLCard.panier.nbr_jour_utilisation
        },
        locationPrice () {
          if (this.isOwner) {
            return this.DLCard.ownerRevenuTTC
          } else {
            return this.DLCard.prix_total_ttc
          }
        },
        image () {
            return this.$parent.user && this.$parent.user.ImageProfil && this.$parent.user.ImageProfil.imageName
        },
        isOwner () {
            return this.DLCard && this.auth == this.idOwner ? true : false
        },
        auth() {
            return this.$parent.user_id
        },
        idOwner() {
            return this.DLCard.proprietaire.id
        },
        idTenant() {
            return this.DLCard.locataire.id
        },
        initials () {
            let initials
            if(this.DLCard.state == "accepted") {
                initials = this.DLCard.proprietaire.prenom.charAt(0) + "" + this.DLCard.proprietaire.nom.charAt(0)
            } else {
                initials = this.DLCard.locataire.prenom.charAt(0) + "" + this.DLCard.locataire.nom.charAt(0)
            }
            if(this.DLCard.state == "canceled") {
                initials = this.canceledByFirstName ? this.canceledByFirstName.charAt(0) + "" + this.canceledByLastName.charAt(0) : null
            }
            return initials
        },
        canceledBy () {
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.id : null
        },
        canceledByFirstName () {
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.prenom : null
        },
        canceledByLastName (){
            return this.DLCard.locationProblem ? this.DLCard.locationProblem.by.nom : null
        },
        DLClass () {
            // let typeMess = this.user_id === message. // si le message est proprio (accepté) : si loc (créé, payé)
            let cardPosition
            if(this.auth == this.idOwner){
                if(this.DLCard.state == "accepted"){
                    cardPosition = 'send-card'
                }
                if(this.DLCard.state == "created"){
                    cardPosition = 'received-card'
                }
                if(this.DLCard.state == "paid"){
                    cardPosition = 'received-card'
                }
            } else if (this.auth == this.DLCard.locataire.id){
                if(this.DLCard.state == "accepted"){
                    cardPosition = 'received-card'
                }
                if(this.DLCard.state == "created"){
                    cardPosition = 'send-card'
                }
                if(this.DLCard.state == "paid"){
                    cardPosition = 'send-card'
                }
            }
            if(this.DLCard.state == "canceled" && this.auth == this.canceledBy){
                cardPosition = 'send-card'
            }else if(this.DLCard.state == "canceled" && this.auth != this.canceledBy){
                cardPosition = 'received-card'
            }

            return cardPosition
        },
        linkDL () {
            return '/user/location/' + this.DLCard.id
        }
    }
}
</script>

<style scoped>
  .click-box {
    cursor: pointer;
  }
</style>