import axios from "axios";

export const getFlagRooms = async function (page = 1) {
  console.log('load page : ' + page)
  let url = '/flag/chat-rooms'
  let UserRooms = {}
  // if (this.$lightyshareConfig && this.$lightyshareConfig.api_base_url) {
  //   url = this.$lightyshareConfig.api_base_url + url
  // }
  const response = await axios.get(url, {
    params: {
      page
    },
  })
  if (response.status === 200) {
    UserRooms.rooms = response.data.rooms
    UserRooms.auth = response.data.auth
    UserRooms.page = response.data.page
    return UserRooms
  } else {
    return false
  }
}

export const getUserRooms = async function (page = 1) {
  // console.log('load page : ' + page)
  let url = '/user/chat-rooms'
  let UserRooms = {}
  // if (this.$lightyshareConfig && this.$lightyshareConfig.api_base_url) {
  //   url = this.$lightyshareConfig.api_base_url + url
  // }
  const response = await axios.get(url, {
    params: {
      page
    },
  })
  if (response.status === 200) {
    UserRooms.rooms = response.data.rooms
    UserRooms.auth = response.data.auth
    UserRooms.page = response.data.page
    return UserRooms
  } else {
    return false
  }
}

export const migrateUser = async function ()
{
  let url = '/user/migration-chat'
  return axios.get(url, {
  })
}

export const sendMessage = async function (message, room_id = false, owner_id = false, receiver_id = false) {
  let datas = new FormData();
  datas.append('message', message)
  if (typeof room_id === 'number' || typeof room_id === 'string') {
    datas.append('room_id', room_id)
  }
  if (typeof owner_id === 'number') {
    datas.append('owner_id', owner_id)
  }
  if (typeof receiver_id === 'number') {
    datas.append('receive_id', receiver_id)
  }
  let url = '/user/send-message'
  return axios({
    method: 'post',
    headers: {"Content-Type": "multipart/form-data"},
    url,
    data: datas
  })
}

export const loadNewMessage = async function (lastRequestAt = false) {
  let url = '/user/check-new-messages'
  return axios.get(url, {
    // params: {
    //   lastRequestAt
    // }
  })
}

export const readRoom = async function (room_id) {
  let url = '/user/read-room'
  return axios.get(url, {
    params: {
      room_id
    }
  })
}
export const checkNewRoom = async function (user_id) {
  let url = '/user/check-room-create/' + user_id
  return axios.get(url, {
  })
}
